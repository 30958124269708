import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet'
import Brands from 'src/components/brands/ItemsBrands/ItemsBrands'

function Stores() {
  return (
    <>
      <Helmet>
        <meta name="keywords" content="marcas" />
      </Helmet>
      <GatsbySeo
        title="Nossas Marcas | Decathlon"
        description="Nossas Marcas | Decathlon"
        canonical="https://www.decathlon.com.br/marcas"
      />
      <Brands />
    </>
  )
}

export default Stores
